import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/index.scss"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Index() {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (counter === 0) {
        setCounter(2)
      } else if (counter > 4) {
        setCounter(1)
      } else {
        setCounter(counter + 1)
      }
    }, 4000)
  }, [counter])

  return (
    <Layout>
      <Seo title="Steen Lauth" />

      <section className="index">
        <StaticImage
          src={"../images/frontpage/frontpage1.jpeg"}
          quality={100}
          alt="Steen Lauth | Tømrer og snedker "
          className={`imageSettings ${counter === 1 ? "showImage" : ""} ${
            counter === 0 ? "fadeInIndex" : ""
          }`}
        />
        <StaticImage
          src={"../images/frontpage/frontpage2.jpeg"}
          quality={100}
          alt="Steen Lauth | Tømrer og snedker "
          className={`imageSettings ${counter === 2 ? "showImage" : ""}`}
        />
        <StaticImage
          src={"../images/frontpage/frontpage3.jpeg"}
          quality={100}
          alt="Steen Lauth | Tømrer og snedker "
          className={`imageSettings ${counter === 3 ? "showImage" : ""}`}
        />
        <StaticImage
          src={"../images/frontpage/frontpage4.jpeg"}
          quality={100}
          alt="Steen Lauth | Tømrer og snedker "
          className={`imageSettings ${counter === 4 ? "showImage" : ""}`}
        />
        <StaticImage
          src={"../images/frontpage/frontpage5.jpeg"}
          quality={100}
          alt="Steen Lauth | Tømrer og snedker "
          className={`imageSettings ${counter === 5 ? "showImage" : ""}`}
        />

        <div className="overlayIndex"></div>
      </section>
    </Layout>
  )
}
